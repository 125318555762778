import React from "react"

import Layout from "../components/Layout"
import Bio from "../components/Bio"
import SEO from "../components/SEO"

const Projects = ({ location }) => (
  <Layout location={location} title="Projekty">
    <SEO
      title="Projekty"
      keywords={[`projekty`, `github`, `memes`, `hello_world`]}
    />
    <article>
      <h1>Moje projekty</h1>
      <a href="https://github.com/pr0gramista/memes-api">
        <h3>Memes API</h3>
      </a>
      <p>
        Memes API pozwala na łatwe wydobywanie treści z popularnych stron z
        memami. Projekt serverless na Pythonie, kiedyś Spring. Na ten moment 8 stron i działa nieprzerwanie od ponad 2 lat.
      </p>
      <a href="https://github.com/pr0gramista/charset_converter">
        <h3>Charset converter</h3>
      </a>
      <p>
        Moja pierwsza Flutterowa paczuszka służąca do konwertowania tekstu do starych zestawów znaków typu Windows 1250 przy pomocy indywidualnych implementacji platformowych.
      </p>
      <a href="https://github.com/pr0gramista/hello_world_keyboard">
        <h3>hello_world_keyboard</h3>
      </a>
      <p>
        Projekt własnoręcznie wykonanej klawiatury mechanicznej, która jest moim
        codziennym i ukochanym narzędziem. Ma praktycznie nieskończone
        możliwości dodawania makr i może służyć jako kierownica do Euro Truck
        Simulator czy mały mikrokontroller.
      </p>
      <a href="https://github.com/pr0gramista/watchwithme">
        <h3>Watch With Me</h3>
      </a>
      <p>
        Watch With Me to aplikacja do oglądania filmików na YouTube wraz z
        przyjaciółmi. Aplikacja dodatkowo zapisuje playlisty i ciągle je
        aktualizuje, abyś zawsze był na bieżąco.
      </p>
      <a href="https://github.com/pr0gramista/spritor">
        <h3>Spritor</h3>
      </a>
      <p>
        Jeden z moich pierwszych projektów. Brute force dla sztuki. Tworzy z
        zestawu obrazków i schematu różne kombinacje. Genialny jeśli chodzi o
        grafike w stylu pixel-art. Oparty o JavaFX, testy UI.
      </p>
      <a href="https://github.com/pr0gramista/meme-analytics">
        <h3>Meme Analytics</h3>
      </a>
      <p>
        Kategoryzowanie memów z wykorzystaniem Elasticsearcha i głebokich sieci
        neuronowych z pomocą Tensorflow. Chcesz trochę memów z małpą polakiem?
      </p>
    </article>
    <Bio />
  </Layout>
)

export default Projects
